import React from 'react';
import { graphql, useStaticQuery, navigate, withPrefix} from 'gatsby';


const Call = props => {
  const data = useStaticQuery(graphql`
    query ContactQuery {
        contactJson {
          contact_button_link
          arrow
        }
    }
   `);
  return (
    <div className="call">
      {props.showButton && (
        <div className="call-box-bottom" onClick={() => {
          navigate(data.contactJson.contact_button_link);
        }}>
          <a className="arrow join-btn">JOIN US</a>
          <div className='icon-div'>
            <img className='icon-img' src={withPrefix(data.contactJson.arrow)} />
          </div>
        </div>
      )}
    </div>
  );
};

export default Call;
